import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BLACK, WHITE } from 'constants.js';

function DesktopLinkButton(props) {
  const { text, link, openInNewTab } = props;
  const [isHovered, setIsHovered] = useState(false);

  return(
    <ButtonContainer
      href={link}
      target={ openInNewTab ? '_blank' : '' }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ButtonUnderline isHovered={isHovered}>
        <ButtonText>{text}</ButtonText>
      </ButtonUnderline>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.a`
  position: relative;
  height: 47px;
  text-decoration: none;
  width: 155px;
`;

const ButtonText = styled.p`
  position: absolute;
  bottom: -10px;
  color: ${WHITE};
  mix-blend-mode: difference;
  font-size: 18px;
  margin-left: 10px;
`;

const ButtonUnderline = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: ${BLACK};
  transition-duration: 0.25s;
  height: ${props => props.isHovered ? 35 : 5}px;
`;

DesktopLinkButton.propTypes = {
  text: PropTypes.string,
  openInNewTab: PropTypes.bool,
  link: PropTypes.string
};

export default DesktopLinkButton;