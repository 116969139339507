import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BLACK, WHITE } from '../../../../constants';

function MobileLinkButton(props) {
  const { text, link, openInNewTab, underlineColor } = props;

  return (
    <Container>
      <ButtonContainer
        href={link}
        target={ openInNewTab ? '_blank' : '' }
      >
        <ButtonText>{text}</ButtonText>
      </ButtonContainer>
      <ButtonUnderline color={underlineColor}/>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.a`
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: ${BLACK};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const ButtonText = styled.p`
  color: ${WHITE};
  mix-blend-mode: difference;
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
`;

const ButtonUnderline = styled.div`
  width: 100%;
  height: 8px;
  background: ${props => props.color};
`;

MobileLinkButton.propTypes = {
  text: PropTypes.string,
  openInNewTab: PropTypes.bool,
  link: PropTypes.string,
  underlineColor: PropTypes.string
}

export default MobileLinkButton;