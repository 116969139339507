import React, { useState, useEffect } from 'react';
import DesktopLandingPage from './pages/landing-page/desktop';
import MobileLandingPage from './pages/landing-page/mobile';

const MOBILE_WIDTH = 1200;

function App() {
  const initialWindowDimensions = { width: window.innerWidth, height: window.innerHeight };
  const [windowDimensions, setWindowDimensions] = useState(initialWindowDimensions);
  const updateWindowDimensions = () => setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  });

  if (windowDimensions.width <= MOBILE_WIDTH) {
    return <MobileLandingPage/>;
  } else {
    return <DesktopLandingPage/>;
  }
}

export default App;
