import React from 'react';
import PropTypes from 'prop-types';

function Circle(props) {
  const { size, color, style } = props;

  return (
    <div
      style={{
        ...style,
        width: size,
        height: size,
        borderRadius: '100%',
        backgroundColor: color,
      }}
    />
  );
}

Circle.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Circle;