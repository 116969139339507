import React from 'react';
import styled from 'styled-components';
import { BLACK, WHITE, PINK, MARGIN, LIGHT_BLUE, YELLOW } from '../../../constants';
import Circle from '../components/Circle';
import MobileLinkButton from './components/MobileLinkButton';

function MobileLandingPage() {
  const horizontalDetailCircles = [];
  const verticalDetailCircles = [];

  for (let i = 0; i < 8; i++) {
    horizontalDetailCircles.push(<DetailCircle key={i}/>);
  }

  for (let i = 0; i < 4; i++) {
    verticalDetailCircles.push(<DetailCircle key={i}/>);
  }

  return (
    <Container>
      <TopContainer>
        <TitleContainer>
          <Title>Jeffrey</Title>
          <Title style={{ marginTop: 35 }}>Goldsmith</Title>
          <TitleBackground/>
        </TitleContainer>
        <ContentContainer>
          <LinkButtonsContainer>
            <MobileLinkButton
              text="Github"
              openInNewTab
              link="https://github.com/jeffreygoldsmith"
              underlineColor={PINK}
            />
            <div style={{ height: 20 }}/>
            <MobileLinkButton
              text="Resume"
              openInNewTab
              link="https://drive.google.com/file/d/1EHTolYg5BoWcZaGfT-A5EMW4H9KJQA8O/view?usp=sharing"
              underlineColor={LIGHT_BLUE}
            />
            <div style={{ height: 20 }}/>
            <MobileLinkButton
              text="Email"
              link="mailto:jeffgold12@gmail.com"
              underlineColor={YELLOW}
            />
          </LinkButtonsContainer>
          <HorizontalDetailContainer>
            <HorizontalDetailRowContainer>
              {horizontalDetailCircles}
            </HorizontalDetailRowContainer>
            <HorizontalDetailRowContainer>
              {horizontalDetailCircles}
            </HorizontalDetailRowContainer>
          </HorizontalDetailContainer>
          <VerticalDetailContainer>
            <div>
              {verticalDetailCircles}
            </div>
            <div>
              {verticalDetailCircles}
            </div>
          </VerticalDetailContainer>
        </ContentContainer>
      </TopContainer>
      <BottomContainer>
        <DescriptionContainer>
          <Description>Computer Engineering at Waterloo.</Description>
          <DescriptionDetail>Previously at Shopify and Coinbase.</DescriptionDetail>
        </DescriptionContainer>
        <Circle
          color={WHITE}
          size="5vw"
          style={{
            position: 'absolute',
            marginTop: '5vh',
            right: '2.5vw',
          }}
        />
        <Circle
          color={WHITE}
          size="15vw"
          style={{
            position: 'absolute',
            marginBottom: '30vw',
            right: '4vw',
          }}
        />
        <Circle
          color={WHITE}
          size="20vh"
          style={{
            position: 'absolute',
            top: '-6vh',
            left: '10vw',
          }}
        />
        <Circle
          color={WHITE}
          size="35vh"
          style={{
            position: 'absolute',
            bottom: '-18vh',
            left: '-12vh',
          }}
        />
      </BottomContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
`;

const TopContainer = styled.div`
  width: 100vw;
  height: 65vh;
  background: ${WHITE};
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  position: relative;
  height: 120px;
  padding-left: ${MARGIN}px;
  padding-top: ${MARGIN}px;
  justify-self: flex-start;
`;

const Title = styled.span`
  position: absolute;
  z-index: 3;
  color: ${WHITE};
  mix-blend-mode: difference;
  font-size: 40px;
  font-weight: 700;
`;

const TitleBackground = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 60px;
  width: 220px;
  height: 60px;
  background: ${BLACK};
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${MARGIN}px;
`;

const HorizontalDetailContainer = styled.div`
  position: absolute;
  right: ${MARGIN * 2}px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
`;

const HorizontalDetailRowContainer = styled.div`
  display: flex;
`;

const VerticalDetailContainer = styled.div`
  position: absolute;
  right: ${MARGIN}px;
  margin-top: 40px;
  display: flex;
`;

const DetailCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${BLACK};
  margin-right: 4px;
  margin-bottom: 4px;
`;

const BottomContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 65vh;
  background: ${BLACK};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionContainer = styled.div`

`;

const Description = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${WHITE};
`;

const DescriptionDetail = styled.p`
  font-size: 14px;
  color: ${WHITE};
  margin-top: -10px;
`;

export default MobileLandingPage;