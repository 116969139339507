import React from 'react';
import styled from 'styled-components';
import { WHITE, BLACK, MARGIN } from 'constants.js';

import DesktopLinkButton from './components/DesktopLinkButton';
import Circle from '../components/Circle';

function DesktopLandingPage() {
  return (
    <Container>
      <LeftHalfContainer>
        <TitleContainer>
          <Title>Jeffrey</Title>
          <Title style={{ marginTop: 50 }}>Goldsmith</Title>
          <TitleBackground/>
        </TitleContainer>
        <LinksContainer>
          <DesktopLinkButton
            text="Github"
            openInNewTab
            link="https://github.com/jeffreygoldsmith"
          />
          <div style={{ width: '2.5vw' }}/>
          <DesktopLinkButton
            text="Email"
            link="mailto:jeffgold12@gmail.com"
          />
          <div style={{ width: '2.5vw' }}/>
          <DesktopLinkButton
            text="Resume"
            openInNewTab
            link="https://drive.google.com/file/d/1EHTolYg5BoWcZaGfT-A5EMW4H9KJQA8O/view?usp=sharing"
          />
        </LinksContainer>
      </LeftHalfContainer>
      <RightHalfContainer>
        <DescriptionContainer>
          <Description>Computer Engineering Student at Waterloo.</Description>
          <DescriptionDetail>Previously at Shopify and Coinbase.</DescriptionDetail>
        </DescriptionContainer>
        <Circle
          color={WHITE}
          size="40vw"
          style={{
            position: 'absolute',
            top: '-20vw',
            left: '2.5vw',
          }}
        />
        <Circle
          color={WHITE}
          size="15vw"
          style={{
            position: 'absolute',
            right: '-2.5vw',
          }}
        />
        <Circle
          color={WHITE}
          size="35vw"
          style={{
            position: 'absolute',
            bottom: '-20vw',
            left: '15vw',
          }}
        />
      </RightHalfContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  overflow: hidden;
`;

const LeftHalfContainer = styled.div`
  width: 50vw;
  height: 100vh;
  position: relative;
  background-color: ${WHITE};
`;

const TitleContainer = styled.div`
  padding-left: ${MARGIN * 2}px;
  padding-top: ${MARGIN}px;
`;

const Title = styled.span`
  position: absolute;
  z-index: 3;
  color: ${WHITE};
  mix-blend-mode: difference;
  font-size: 56px;
  font-weight: 700;
`;

const TitleBackground = styled.div`
  position: absolute;
  z-index: 2;
  margin-top: 40px;
  margin-left: 40px;
  width: 250px;
  height: 75px;
  background: ${BLACK};
`;

const LinksContainer = styled.div`
  position: absolute;
  left: ${MARGIN}px;
  bottom: ${MARGIN}px;
  display: flex;
  align-items: center;
  height: 50px;
`;

const RightHalfContainer = styled.div`
  width: 50vw;
  height: 100vh;
  position: relative;
  background-color: ${BLACK};
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  position: absolute;
  left: ${MARGIN * 2}px;
`;

const Description = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${WHITE};
`;

const DescriptionDetail = styled.p`
  font-size: 14px;
  color: ${WHITE};
  margin-top: -10px;
`;

export default DesktopLandingPage;
